@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../fonts/montserrat-v23-latin_cyrillic-regular.woff2') format('woff2'),
    url('../fonts/montserrat-v23-latin_cyrillic-regular.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('../fonts/montserrat-v23-latin_cyrillic-500.woff2') format('woff2'),
    url('../fonts/montserrat-v23-latin_cyrillic-500.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('../fonts/montserrat-v23-latin_cyrillic-700.woff2') format('woff2'),
    url('../fonts/montserrat-v23-latin_cyrillic-700.woff') format('woff');
}
